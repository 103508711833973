import React, { useContext } from "react";
import "../CSS/Form.css";
import { Context } from "../Context/Provider";
import forca from '../Images/forca.png';
import vida from '../Images/vida.png';
import escudo from '../Images/escudo.png';
import custo from '../Images/custo.png';
import { api } from "../services/api";
import { notify } from '../notifications/toast';
// Feito com ajuda de Pesquisa no https://reactjs.org/docs/handling-events.html
function Form(url) {
  const {
    price, setPrice, name, setName, description, setDescription, image,
    setImg, life, setLife, attack, setAttack, defense, setDefense, rarity,
    setRarity, cardSuper, setCardSuper, deckCopy,
  } = useContext(Context);
  /*
  const onSaveButtonClick = () => {
    const maxCard = 90;
    const minCard = 0;
    const somaTotal = 210;
    const attr1N = Number(attr1);
    const attr2N = Number(attr2);
    const attr3N = Number(attr3);
    const somaAttr = attr1N + attr2N + attr3N;
    if (
      cardName === '' || image === '' || cardDescription === ''
      || attr1 > maxCard || attr1 < minCard
      || attr2 > maxCard || attr2 < minCard
      || attr3 > maxCard || attr3 < minCard
      || somaAttr > somaTotal
    ) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  } */
  const currDeck = JSON.parse(localStorage.getItem('currentDeck'));

  const handleSaveButton = async () => {
    const { data } = await api.post('/creation', {
      "name": name,
      "image": image,
      "description": description,
      "rarity": rarity,
      "life": life,
      "attack": attack,
      "defense": defense,
      "price": price,
      "cardSuper": cardSuper,
      "deckId": currDeck.id
    });
    
    if (data && data.status) return notify({ type: "error", message: data.message });
    notify({ type: "success", message: "Carta criada com sucesso!" })
/*     setDeckCopy([
      ...mainDeck,
      {
        deckName: testDeck.nome,
        cardPrice,
        cardName,
        cardDescription,
        image,
        rarity,
        attr1,
        attr2,
        attr3,
        cardTrunfo,
      },
    ]),
    setTestDeck(prevState => {
      return { ...prevState, nome: prevState.nome, cartas: [...prevState.cartas, cartinha]}} )
      localStorage.setItem("baralhos", JSON.stringify([{...testDeck}]));
      setFiltered(
        [...mainDeck],
        ); */
        /* eslint-disable */ setPrice(0),
        /* eslint-disable */ setName(""),
        /* eslint-disable */ setDescription(""),
        /* eslint-disable */ setImg(""),
        /* eslint-disable */ setLife(0),
        /* eslint-disable */ setAttack(0),
        /* eslint-disable */ setDefense(0),
        /* eslint-disable */ setRarity("Normal"),
        /* eslint-disable */ setCardSuper(false);
  };
  let trunfoCheck = false;
  if (!deckCopy) {
    const trunfo = mainDeck.filter((carta) => carta.cardTrunfo === true);
    trunfoCheck = trunfo.length > 0;
  }
  else {
    const trunfo = deckCopy.filter((carta) => carta.cardTrunfo === true);
    trunfoCheck = trunfo.length > 0;
  }
  return (
    <div className="form-total">
      <form className="formulario">
        <div id="top-section-form">
          <div id="custo-area">
            <div className="label-area">
              <img height="17px" src={custo} />
              <label htmlFor="custo-input">Price</label>
            </div>
            <input
              id="custo-input"
              type="text"
              name="cardPrice"
              value={price}
              maxLength={1}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
          <input
            className={name.length >= 20 ? "input-form-max" : "input-form"}
            placeholder="Nome da Carta"
            type="text"
            name="cardName"
            value={name}
            maxLength={20}
            onChange={(e) => setName(e.target.value)} // o que for escrito, vira valor do Input
            id="name-input"
          />
          <div id="rarity-area">
            <div className="label-area">
              <label htmlFor="select-form-rarity" /> Rarity
            </div>
            <select
              id="select-form-rarity"
              name="rarity"
              value={rarity}
              data-testid="rare-input"
              onChange={(e) => setRarity(e.target.value)}
            >
              <option>Normal</option>
              <option>Raro</option>
              <option>Lenda</option>
            </select>
          </div>
        </div>
        <div id="img-area">
          <input
            name="image"
            className="input-form"
            placeholder="Image URL"
            type="text"
            onChange={(e) => setImg(e.target.value)}
            id="image-input"
            value={image}
          />
          {/*           ou
          <input
            type="file"
            alt="Submit"
            width="20"
            height="20"
            id="upload-img"
            onChange={(e) => setImg(e.target.value)}
            /> */}

        </div>
        <div className="number-area">
          <div id="status-name">
            <span id="title-status">Life</span>
            <span id="title-status">Atack</span>
            <span id="title-status">Defense</span>
          </div>
          <div id="attr-area">
            <div id="attr1-area">
              <img height="25px" src={vida} />
              <input
                className={life === '999' ? "input-form-n-max" : "input-form-n"}
                type="text"
                placeholder="Valor atributo 1"
                name="life"
                maxLength={3}
                max={999}
                onChange={(e) => setLife(e.target.value)}
                value={life}
                id="attr1-input"
              />
            </div>
            <div id="attr2-area">
              <img height="21px" src={forca} />
              <input
                className={attack === '999' ? "input-form-n-max" : "input-form-n"}
                type="text"
                inputMode="numeric"
                placeholder="Valor atributo 2"
                name="attack"
                maxLength={3}
                max={999}
                onChange={(e) => setAttack(e.target.value)}
                value={attack}
                id="attr2-input"
              />
            </div>
            <div id="attr3-area">
              <img height="21px" src={escudo} />
              <input
                className={defense === '999' ? "input-form-n-max" : "input-form-n"}
                type="text"
                placeholder="Valor atributo 3"
                name="defense"
                maxLength={3}
                max={999}
                onChange={(e) => setDefense(e.target.value)}
                value={defense}
                id="attr3-input"
              />
            </div>
          </div>
        </div>
        <textarea
          className={description.length >= 120 ? "input-form-max" : "input-form"}
          id="description-input"
          type="textarea"
          placeholder="Descrição da Carta"
          name="description"
          value={description}
          maxLength={120}
          onChange={(e) => setDescription(e.target.value)}
          data-testid="description-input"
        />
        <div id="checkbox-save">
          {trunfoCheck ? (
            <span id="aviso-sp" >Já existe uma Super! em seu baralho</span>
          ) : (
            <div className="secao-checkbox">
              <input
                className="input-check-trunfo"
                name="cardTrunfo"
                type="checkbox"
                checked={cardSuper}
                onChange={() => setCardSuper(!cardSuper)}
                data-testid="trunfo-input"
              />
              <span className="texto-trunfo">Super!</span>
            </div>
          )}
          <button
            id={/* isSaveButtonDisabled ? "botao-salvar-off" : */ "botao-salvar-on"}
            name="save-button"
            type="button"
            disabled={false}
            onClick={handleSaveButton}
            data-testid="save-button"
          >
            Salvar
          </button>
        </div>
      </form>
    </div>
  );
}
export default Form;
