import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  .Toastify__toast--info {
    color: #4B4A4B;
    background: rgba(213, 224, 224);
  }
  .Toastify__toast--success {
    color: #4B4A4B;
    background: rgba(213, 224, 224);
  }
  .Toastify__toast--warning {
    color: #4B4A4B;
    background: rgba(213, 224, 224);
  }
  .Toastify__toast--error {
    color: #4B4A4B;
    background: rgba(213, 224, 224);
  }
`;