import React from 'react';
import { Route, Routes } from 'react-router';
import { useLocation } from 'react-router-dom';
import { GlobalStyles } from './notifications/toastGlobalStyles';
import Notifications from './notifications/toast';
import Header from './components/Header';
import MainPage from './pages/MainPage';
import Colecao from './pages/Colecao';
import Decks from './pages/Decks';
import Menu from './pages/Menu';
import './App.css'

function App() {
  const { pathname } = useLocation();
  return (
    <>
    <Header url={pathname} />
      <Routes>
        <Route path="/" element={<Menu />} />
        <Route path="/creation" element={<MainPage />} />
        <Route path="/colection/:cardId?" element={<Colecao />} />
        <Route path="/decks/:decksId?" element={<Decks />} />
      </Routes>
      <GlobalStyles />
      <Notifications />
    </>
  );
}
export default App;
