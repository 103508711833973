import React, { useContext, useState, useEffect } from 'react';
import { Context } from '../Context/Provider';
import '../CSS/Card.css';
import forca from '../Images/forca.png';
import vida from '../Images/vida.png';
import escudo from '../Images/escudo.png';
import normal from '../Images/Normal.png';
import raro from '../Images/Raro.png';
import lenda from '../Images/Lenda.png';
import { api } from "../services/api";
import { notify } from '../notifications/toast';
// Feito com ajuda de Pesquisa no https://reactjs.org/docs/handling-events.html
function Card() {

  const [cards, setCards] = useState();

  useEffect(() => {
    const fecthCards = async () => {
      const { data } = await api.get('/colection');
      if (data && data.status) return notify({ type: "error", message: data.message });
      setCards(data);
    }
    fecthCards();
  }, []);

  const {
    price,
    name,
    description,
    image,
    life,
    attack,
    defense,
    rarity,
    cardSuper,
  } = useContext(Context);

  return (
    <div id="card-preview-area">
      <div id={cardSuper === false ? "card-preview" : "card-super-preview"}>
        <div className="card-top-preview">
          <span id="card-price">{ price }</span>
          <p id="card-name">
            { name }
          </p>
          { (rarity === 'Normal')
          && (
            <img
              id="rarity-card"
              src={ normal }
              alt={ rarity }
            />
          )}
          { (rarity === 'Raro')
          && (
            <img
              id="rarity-card"
              src={ raro }
              alt={ rarity }
            />
          )}
          { (rarity === 'Lenda')
          && (
            <img
              id="rarity-card"
              src={ lenda }
              alt={ rarity }
            />
          )}
          {/* { cardTrunfo === true
            ? <span id="trunfo-card-text"> Super </span>
            : <span id="logo-card-text"> Simples </span> } */}
        </div>
        <div id="card-img-attr">
          <img
            className="card-image"
            src={ image }
            alt={ name }
            height="190px"
            width="200px"
            id="card-image"
          />
          <div className="card-secao-attr">
            <div id="card-attr1">
              <img className="status"
                height="20px"
                alt="ícone de coração"
                src={vida}>
              </img>
              <span>{ life }</span>
            </div>
            <div id="card-attr2">
            <img className="status"
                height="20px"
                alt="ícone de punho"
                src={forca}>
              </img>
              <span>{ attack }</span>
            </div>
            <div id="card-attr3">
              <img className="status"
                height="20px"
                alt="ícone de escudo"
                src={escudo}>
              </img>
              <span>{ defense }</span>
            </div>
          </div>
        </div>
        <label htmlFor="card-description">
          <textarea
            className="card-description"
            id="card-description-preview"
            value={ description }
            disabled={true}
            placeholder="Descrição da carta"
          >
            { description }
          </textarea>
        </label>
      </div>
    </div>
  );
}
export default Card;
