import React, { useContext } from 'react';
import '../CSS/Search.css';
import { Context } from '../Context/Provider';
import { notify } from '../notifications/toast';

function Search() {
  const {
    cards,
    setFiltered,
    setDeckCopy
  } = useContext(Context);

  const filtrar = (e) => {
    setFiltered(e);
  };
  const buscarRaridade = (filtro) => {
    if (filtro === 'Raro') {
      const raros = cards.filter((card) => card.rarity === 'Raro');
      return setDeckCopy(raros);
      
    }
    if (filtro === 'Normal') {
      const normais = cards.filter((item) => item.rarity === 'Normal');
      return setDeckCopy(normais);
    }
    if (filtro === 'Lenda') {
      const lendas = cards.filter((item) => item.rarity === 'Lenda');
      return setDeckCopy(lendas);
    }
    if (filtro === 'Todas') {
      return setDeckCopy(cards);
    }
    if (filtro === 'Super') {
      const superCard = cards.find((item) => item.cardSuper);
      if(superCard === undefined || superCard === false) {
        
        setDeckCopy(cards);
        return notify({ type: "warning", message: "Você ainda não possui uma Super Carta" });
      }
      return setDeckCopy([superCard]);
    }
    if (filtro) {
      const filtradoNome = cards.filter((item) => item.name.toUpperCase().includes(filtro.toUpperCase()));
      if(!filtradoNome) {
        return setDeckCopy(cards);
      }
      return setDeckCopy(filtradoNome);
    }
    else {
      return setDeckCopy(cards);
    }
  }

  return (
    <div className="search-area">
      <label
        className=" lbl lbl-seach-by"
        htmlFor="search-label-busca"
      >
        Filtrar por:
      </label>
      <div id="search-inputs">
        <input
          id="input-name"
          placeholder="Nome da carta"
          onChange={(e) => {
            filtrar(e.target.value);
            buscarRaridade(e.target.value);
          }}
        />
        <label className="lbl lbl-or"> ou </label>
        <select
          id="card-rarity"
          onChange={(e) => {
            filtrar(e.target.value);
            buscarRaridade(e.target.value);
          }}
        >
          <option className="rarity-option">Todas</option>
          <option className="rarity-option">Normal</option>
          <option className="rarity-option">Raro</option>
          <option className="rarity-option">Lenda</option>
          <option className="rarity-option">Super</option>
        </select>
      </div>
    </div >

  );
}

export default Search;
