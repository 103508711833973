import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../Context/Provider';
import '../CSS/Header.css';
import LongMenu from './MenuButton';

function Header(page) {
  const { cards, setId, decks, mainDeck, setLoggedOn,
    logged, setTestDeck, setDeckCopy } = useContext(Context);
    
    const currDeck = JSON.parse(localStorage.getItem('currentDeck'));

  const handleNewDeck = () => {
    setDeckCopy(prevState => {
      return { ...prevState, nome: [...decks], cartas: [...mainDeck] }
    })
    setId(true);
    setLoggedOn(!logged);
    setTestDeck([]);
  }

  return (
    <header className="header">
      <div id={page.url === '/' && "menu-header-title"}>
        <span id="title">
          {page.url === '/' && "Deck Maker"}
        </span>
      </div>

      {page.url === '/creation'
        && (
          <header className="header">
          <div id={`creation-header-title`}>
            <span id="title">
              {page.url === '/creation' && currDeck.name}
            </span>
          </div>
            <div id="mid-creation">
              <span id="mid-title">Criação</span>
            </div>
            {window.innerWidth < 900 ? <LongMenu url={page} />
              :
              <nav id="btns-creation">

                <Link to="/colection">
                  <button
                    onClick={() => setId(true)}
                    type="button"
                    id="to-colection"
                  >
                    Coleção
                  </button>
                </Link>
                <Link to="/decks">
                  <button
                    onClick={() => setId(true)}
                    type="button"
                    id="to-decks"
                  >
                    Baralhos
                  </button>
                </Link>
                <Link to="/">
                  <button
                    onClick={() => { handleNewDeck() }}
                    type="button"
                    id="to-menu"
                  >
                    Sair
                  </button>
                </Link>
              </nav>
            }
          </header>
        )
      }

      {page.url === '/colection'
        && (
          <header className="header">
          <div id={`creation-header-title`}>
            <span id="title">
              Deck Maker
            </span>
          </div>
            <div id="mid-colection">
              <span id="mid-title">{`Coleção(${cards.length})`}</span>
            </div>
            <nav id="btns-colection">
              <Link to="/creation">
                <button
                  type="button"
                  id="to-creation"
                >
                  Voltar
                </button>
              </Link>
            </nav>
          </header>
        )
      }

      {page.url === '/decks'
        && (
          <header className="header">
          <div id={`creation-header-title`}>
            <span id="title">
              Deck Maker
            </span>
          </div>
            <div id="mid-decks">
              <span id="mid-title">Baralhos</span>
            </div>
            {window.innerWidth < 900 ? <LongMenu url={page} />
              :
              <nav id="btns-decks">
                <Link to="/colection">
                  <button
                    onClick={() => setId(true)}
                    type="button"
                    id="to-colection"
                  >
                    Coleção
                  </button>
                </Link>
                <Link to="/creation">
                  <button
                    onClick={() => { handleNewDeck() }}
                    type="button"
                    id="to-menu"
                  >
                    Voltar
                  </button>
                </Link>
              </nav>
            }
          </header>
        )
      }

    </header >)
}
export default Header;
