import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Context } from '../Context/Provider';
import '../CSS/Colecao.css';
import forca from '../Images/forca.png';
import vida from '../Images/vida.png';
import escudo from '../Images/escudo.png';
import image from '../Images/sem-imagem.png';
import { api } from "../services/api";
import { notify } from '../notifications/toast';
import Search from '../components/Search';

function Colecao() {

  let { cardId } = useParams();
  const {
    select,
    cards,
    setCards,
    deckCopy, setDeckCopy
  } = useContext(Context);
  
  useEffect(() => {
    const fecthCards = async () => {
      const { data } = await api.get('/colection');
      if (data && data.status) return notify({ type: "error", message: data.message });
      setCards(data);
    }
    fecthCards();
  }, [setCards])
  
  useEffect(() => setDeckCopy(cards), [cards, setDeckCopy])

  const apagar = async (carta) => {
    cardId = String(carta.id);

    const { data } = await api.delete(`/colection/${cardId}`);
    if (data && data.status) return notify({ type: "error", message: data.message });
    const att = cards.filter((i) => i.id !== carta.id)
    setCards(att);
    return notify({ type: "success", message: "Carta excluída com sucesso!" })
  };

  return (
    <div className="colection-deck-section">
      <Search cards={deckCopy} />

      <p>{select}</p>
      <div className="colection-area-cartas">
        {deckCopy.length === 0 ? <p>Seu baralho está vazio!</p>
          : deckCopy.map((carta, index) => (
            <div className={carta.cardSuper === true ? "colection-carta-super" : "colection-cartas"} key={index}>
              <div className="colection-top-text">
                <span id="colection-card-price">{carta.price}</span>
                <p id="colection-card-name">
                  {carta.name}
                </p>
                <span id={`colection-rarity-card-${carta.rarity}`} />
              </div>
              <div id="colection-img-attr-area">
                <img
                  className="colection-imagem"
                  src={carta.image ? carta.image : image}
                  alt={carta.name}
                  height="200px"
                  width="200px"
                  data-testid="image-card"
                />
                <div className="colection-attr-section">
                  <div id="colection-attr1-card">
                    <img className="colection-card-text-bottom"
                      data-testid="attr1-card"
                      height="25px"
                      alt="ícone de coração"
                      src={vida}>
                    </img>
                    <span>{carta.life}</span>
                  </div>
                  <div id="colection-attr2-card">
                    <img className="colection-card-text-bottom"
                      height="20px"
                      alt="ícone de punho"
                      src={forca}>
                    </img>
                    <span>{carta.attack}</span>
                  </div>
                  <div id="colection-attr3-card">
                    <img className="colection-card-text-bottom"
                      height="20px"
                      alt="ícone de escudo"
                      src={escudo}>
                    </img>
                    <span>{carta.defense}</span>
                  </div>
                </div>
              </div>
              <div
                id="colection-description-area">
                <textarea
                  className="colection-card-text-bottom"
                  id={carta.description.length > 70 ? "colection-description" : "colection-description-small"}
                  disabled={true}
                  defaultValue={carta.description}
                />
              </div>
              <button
                id="colection-delete-button"
                name={carta.name}
                type="button"
                data-testid="delete-button"
                onClick={() => apagar(carta)}
              >
                Excluir
              </button>
            </div>
          ))}
      </div>
    </div>
  );
}
export default Colecao;
