import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from 'react-router-dom';
import { useContext } from 'react';

import { Context } from '../Context/Provider';
import img from '../Images/logo-header2.png';
import criacao from '../Images/img-criacao2.png';
import '../CSS/MenuButton.css';

const options = [
  'Coleção',
  'Baralhos',
  'Sair',
];

const ITEM_HEIGHT = 30;

export default function LongMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const { setId, decks, mainDeck, setLoggedOn, logged, setTestDeck, setDeckCopy } = useContext(Context);

  const handleNewDeck = () => {
    localStorage.clear();
    setId(true);
    setLoggedOn(!logged);
    setTestDeck([]);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div id="button-long-menu">
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '18ch',
            padding: 0,
            backgroundColor: '#F96565'
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} selected={option === 'Pyxis'} onClick={ handleClose }>
            {option === 'Sair' && (
            <Link className="item-long-menu" to="/">
              <img alt={`${option}-${criacao}`} height="25px" src={criacao} />
                <button
                    className="long-menu-button"
                    type="button"
                    onClick={ () => handleNewDeck()}
                >
                    {option}
                </button>
            </Link>)
            }
            {option === 'Coleção' && (
            <Link className="item-long-menu" to="/colection">
              <img alt={`${option}-${img}`} height="25px" src={img} />
                <button
                    className="long-menu-button"
                    type="button"
                    onClick={() => setId(true)}
                >
                    {option}
                </button>
            </Link>)
            }
            {option === 'Baralhos' && (
                <Link className="item-long-menu" to="/decks">
                    <img alt={`${option}-${img}`} height="25px" src={img} />
                    <button
                        className="long-menu-button"
                        type="button"
                        onClick={() => setId(true)}
                    >
                        {option}
                    </button>
                </Link>)
            }
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}