import React, { useContext, useEffect } from 'react';
import { Context } from '../Context/Provider';
import '../CSS/Decks.css';
import BasicModal from '../components/BasicModal';
import { notify } from '../notifications/toast';
import { api } from '../services/api';

function Decks() {
  const { decks, setDecks, cards, setCards } = useContext(Context);

  useEffect(() => {
    const fecthDeck = async () => {
      const { data } = await api.get('/decks');
      if (data && data.status) return notify({ type: "error", message: data.message });
      return setDecks(data);
    }
    fecthDeck();
  });

  useEffect(() => {
    const fecthCards = async () => {
      const { data } = await api.get('/colection');
      if (data && data.status) return notify({ type: "error", message: data.message });
      setCards(data);
    }
    fecthCards();
  }, []);

  return (
    <div>
      <div className="secao-decks">
        <div id="decks-list">

          {decks && decks.map((deck, index) => (
            <div id="deck" key={index}>
              <BasicModal decksData={decks} deck={deck.name} cardsData={cards}/>
            </div>
            /*             <button
            key={index}
            onClick={() => handleSelectedDeck(deck)}
            type="button"
            className="decks-card"
            >
            {deck}
            </button> */
          ))}
        </div>
      </div>
    </div>
  );
}
export default Decks;