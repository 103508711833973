import React, { useContext, useEffect } from 'react';
import { api } from '../services/api';
import { notify } from '../notifications/toast';
import { Context } from '../Context/Provider';
import Form from '../components/Form';
import Card from '../components/Card';
import '../CSS/MainPage.css';

function MainPage() {

  const { cards, setCards } = useContext(Context);

  useEffect(() => {
    const fecthCards = async () => {
      const { data } = await api.get('/colection');
      if (data && data.status) return notify({ type: "error", message: data.message });
      setCards(data);
    }
    fecthCards();
  }, []);

  const currentDeck = localStorage.getItem('currentDeck');

  return (
    <div className="creation-page">
      <Form currDeck={currentDeck} />
      <Card />
    </div>
  )
}
export default MainPage;
