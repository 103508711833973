import React, { useState } from 'react';
import { Context } from '../Context/Provider';
import { Modal, Typography, Button, Box } from '@mui/material';
import '../CSS/BasicModal.css'
import forca from '../Images/forca.png';
import vida from '../Images/vida.png';
import escudo from '../Images/escudo.png';
import normal from '../Images/Normal.png';
import raro from '../Images/Raro.png';
import lenda from '../Images/Lenda.png';
import image from '../Images/sem-imagem.png';
import { api } from '../services/api';
import { notify } from '../notifications/toast';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#fed8d8',
    fontWeight: 500,
    color: '#f9f7fa',
    boxShadow: '1px 2px black',
    p: 6,
    disableScrollLock: true,
};

export default function BasicModal(decksData) {
    const [open, setOpen] = React.useState(false);
    const [selectedDeck, setSelectedDeck] = useState([]);
    const [currDeck, setCurrDeck] = useState();


    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    const handleSelected = (selected) => {
        const selectedDeck = decksData.decksData.find((deck) => deck.name === selected);
        setCurrDeck(selected);
        const deckSelected = decksData.cardsData.filter((card) => card.deckId === selectedDeck.id);
        console.log(deckSelected);
        //cartas do deck selecionado
        setSelectedDeck(deckSelected);
    }

    const apagar = async (target) => {
        const toDelete = decksData.decksData.find((deck) => deck.name === target);
        let deckId = String(toDelete.id);
        console.log(deckId);
        const { data } = await api.delete(`/decks/${deckId}`);
        if (data && data.status) return notify({ type: "error", message: data.message });
        
        handleClose();
        return notify({ type: "success", message: "Baralho excluído com sucesso!" })
      };

    return (
        <div id="modal">
            <Button
                id="modal-button"
                onClick={() => {
                    handleOpen();
                    handleSelected(decksData.deck);
                }}
            >
                {decksData.deck}
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}>
                    <div id="modal-top">
                        <div id="btn-close-area">
                            <button id='btn-close' type='button' onClick={handleClose}>
                                X
                            </button>
                        </div>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {decksData.deck}
                        </Typography>
                    </div>
                    <div id="modal-modal-description" sx={{ mt: 2 }}>

                        {selectedDeck.length === 0
                        ? <span id="empty">Você anda não tem cartas neste baralho</span>
                        : selectedDeck.map((card) => (
                            <div id="grid-card-small" key={card.id}>
                                <div id="top-card">
                                    <span id="grid-card-price">{card.price}</span>
                                    <span>{card.name}</span>
                                    {(card.rarity === 'Normal')
                                        && (
                                            <img
                                                id="grid-card-rarity"
                                                src={normal}
                                                alt={card.rarity}
                                            />
                                        )}
                                    {(card.rarity === 'Raro')
                                        && (
                                            <img
                                                id="grid-card-rarity"
                                                src={raro}
                                                alt={card.rarity}
                                            />
                                        )}
                                    {(card.rarity === 'Lenda')
                                        && (
                                            <img
                                                id="grid-card-rarity"
                                                src={lenda}
                                                alt={card.rarity}
                                            />
                                        )}
                                </div>
                                <img id="grid-card-image" src={card.image ? card.image : image} alt={`img-${card.name}`} />
                                <div id="attributes">
                                    <div id="attribute-life">
                                        <img height="20px" alt="heart icon" src={vida} />
                                        <span>
                                            {card.life}
                                        </span>
                                    </div>
                                    <div id="attribute-strength">
                                        <img height="16px" alt="crossed swords icon" src={forca} />
                                        <span>
                                            {card.attack}
                                        </span>
                                    </div>
                                    <div id="attribute-defense">

                                        <img height="16px" alt="shield icon" src={escudo} />
                                        <span>
                                            {card.defense}
                                        </span>
                                    </div>
                                </div>
                                <hr id="description-line" />
                                <textarea
                                    id="grid-description"
                                    value={card.description}
                                    readOnly={true}
                                >
                                </textarea>
                            </div>
                        ))}

                    </div>

                <button type="button" onClick={ () => apagar(decksData.deck) }>Excluir</button>
                </Box>
            </Modal >
        </div >
    );
}