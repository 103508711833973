import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const Context = createContext();

export function Provider({ children }) {
  const [decks, setDecks ] = useState([]);
  const [price, setPrice] = useState(0);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImg] = useState('');
  const [life, setLife] = useState('0');
  const [attack, setAttack] = useState('0');
  const [defense, setDefense] = useState('0');
  const [rarity, setRarity] = useState('Normal');
  const [cardSuper, setCardSuper] = useState(false);

  const [isSaveButtonDisabled, setButtonState] = useState(true);
  const [cards, setCards] = useState([]);
  const [id, setId] = useState(false);
  const [mainDeck, setMainDeck] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [deckCopy, setDeckCopy] = useState([]);
  const [testDeck, setTestDeck ] = useState([]);
  const [select, setSelection ] = useState('');
  const [logged, setLoggedOn ] = useState(false);

  return (
    <Context.Provider
      value={ {
        price,
        setPrice,
        name,
        setName,
        description,
        setDescription,
        image,
        setImg,
        life,
        setLife,
        attack,
        setAttack,
        defense,
        setDefense,
        rarity,
        setRarity,
        cardSuper,
        setCardSuper,
        deckCopy,
        setDeckCopy,
        cards, setCards,
        id,
        setId,
        mainDeck,
        setMainDeck,
        isSaveButtonDisabled,
        setButtonState,
        filtered,
        setFiltered,
        decks,
        setDecks,
        testDeck,
        setTestDeck,
        logged,
        setLoggedOn,
        select,
        setSelection
      } }
    >
      { children }
    </Context.Provider>
  );
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};
