import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../CSS/Menu.css';
import criacao from '../Images/logo-header2.png';
//import { Context } from '../Context/Provider';
import { notify } from '../notifications/toast';
import { api } from '../services/api';


function Menu() {

  const redirect = useNavigate();

  const [createDeck, setCreateDeck] = useState(false);
  const [deckName, setDeckName] = useState('');

  localStorage.clear()

  const handleCreation = async () => {
    const { data } = await api.post('/', {
      "name": deckName,
    });
    console.log(data);
    if (data && data.status) return notify({ type: "error", message: data.message });
    localStorage.setItem('currentDeck', JSON.stringify(data));
    notify({ type: "success", message: "Baralho criado com sucesso! \n Agora vamos criar suas cartas!" })
    redirect('/creation');
  }

  return (
    <div className="menu">
      <img height="120px" alt="two aces of hearts" src={criacao} />
      <div id={createDeck ? "interation-area-plus" : "interation-area"}>
        {createDeck
          ? (
            <form id="deck-creation-input">
              <label
                id="lbl-deck-name"
                htmlFor={deckName.length >= 20 ? "name-deck-max" : "name-deck"}
              />  Dê um nome ao seu baralho
              <input
                id={deckName.length >= 20 ? "name-deck-max" : "name-deck"}
                maxLength={20}
                onChange={({ target }) => { setDeckName(target.value) }}
                type="text"
              />
            </form>
          )
          : null}
        {createDeck
          ? (
            <div id="btn-area-plus">
              <button
                type="button"
                id={deckName.length >= 20 ? "btn-create-deck-dis" : "btn-create-deck-act"}
                disabled={deckName.length >= 20 ? true : false}
                onClick={handleCreation}
              >
                Avançar
              </button>

              <button type="button" id="btn-back" onClick={() => {
                setCreateDeck(!createDeck)
              }}>
                Voltar
              </button>
            </div>
          )
          : (
            <div id="btn-area">
              <button
                type="button"
                id="btn-open-input"
                onClick={() => {
                  setCreateDeck(true)
                }}
              >
                Criar Baralho
              </button>


              <button type="button" id="btn-to-decks">
                <Link to="/decks">
                  Baralhos
                </Link>
              </button>

              <button type="button" id="btn-to-colection">
                <Link to="/colection">
                  Coleção
                </Link>
              </button>
            </div>
          )
        }
      </div>
    </div>

  );
}
export default Menu;